.tnc_content{
    padding: 30px;
    padding-bottom: 0;
    min-height: calc(100vh - 47px);
    margin-bottom: 2em;
    max-width: 850px;
    margin: 0 auto;

}

.tnc.info {
    margin-bottom: 1em;
    color: grey;
}

.tnc.head {
    margin-bottom: 1.3em;
    font-size: 20px;
}
