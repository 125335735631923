.confirm-user-details-card .confirm-user.head {
    color: grey;
    margin-bottom: 1em;
    font-weight: 500;
}

.confirm-user-details-card .card-action-cnt button {
    background: #00aeff;
    color: #fff;
}
