.app-bar.menu-bar {
    color: #00aeef;
    background: #fff;
}

.menu-bar .title {
    flex-grow: 1;
    line-height: 1em;;
}

.menu-bar .MuiSkeleton-root.title {
    width: 100%;
    flex-grow: initial;
    height: 50px;
    margin-left: 8px;
}

.menu-bar .avatar {
    background: #00aeef;
    margin-right: 1em;
}

.app-bar .hidden {
    display: none;
}

.menu.btn {
    color: #00aeef;
}

.app-bar.menu-bar .book-new-btn {
    margin-bottom: -6px;
    font-size: 13px;
    margin-right: -16px;
}
