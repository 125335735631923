.receiptPadding {
    padding: 24px 16px 0;
}

.receiptContainer {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

@media screen and (min-width: 601px) {
    .receiptContainer {
        border: 1px solid #ddd;
        padding: 8px 0 16px;
        border-radius: 8px;
    }

    .receiptPadding {
        display: flex;
        flex-direction: column;
    }

    .receiptPadding::after {
        content: '';
        flex-grow: 1;
    }

    .receiptPadding::before {
        content: '';
        flex-grow: 1;
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
}

.logoText {
    color: grey;
    font-size: 12px;
}

.doxperLogo {
    height: 2em;
    margin-left: 1em;
}

.docNameContainer {
    background: #00aeff;
    color: #fff;
    height: 5em;
    padding-top: 0.5em;
    font-size: 18px;
}

.infoPaper {
    margin: -2.5em 2em 0;
}

.amountContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
    border-bottom: 2px solid #00aeff;
}

.amount {
    font-size: 20px;
    width: 100%;
    flex-basis: 100%;
    line-height: 2em;
}

.amountStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: grey;
    font-size: 14px;
}

.amountStatus svg {
    color: #4ba528;
    margin-right: 4px;
    font-size: 18px;
}

.infoPaper .listItem {
    padding-top: 4px;
    padding-bottom: 4px;
}
