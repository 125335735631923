.listing-card {
    position: relative;
    margin: 1.5em 0 2em;
}

.info-container {
    display: flex;
    justify-content: space-around;
}

.doc-info-container {
    display: inline-flex;
    margin-right: 4px;
}

.slot-info {
    display: inline-flex;
    flex-direction: column;
    margin-right: 0;
    margin-left: auto;
}

.slot-info > div {
    font-size: 12px;
    margin-bottom: 8px;
}

.doc.icon {
    height: 2.5em;
    width: 2.5em;
    color: #ccc;
}

.doc.avatar {
    height: 2.5em;
    width: 2.5em;
    background: transparent;
    margin-right: 4px;
}

.availability {
    padding: 0 0.5em;
    background: #ef9494;
    color: red;
    white-space: nowrap;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.availability.available {
    background: #def3de;
    color: #3a855a;
}

.availability .circle-loader{
    margin-left: 4px;
}

@media screen and (max-width: 600px) {
    .speciality,
    .doc.name {
        width: 130px;
        user-select: none;
    }
}

.speciality.MuiTypography-root {
    font-size: 12px;
    color: grey;
}

.listing-card .location.MuiTypography-root {
    font-size: 12px;
}

.last-visit {
    color: grey;
}

div.amount {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
}

.amount .fee {
    font-weight: 500;
}

.book-apt.dox.btn.light {
    border: none;
    text-transform: capitalize;
}

.card.slot-selection-content {
    padding-top: 0;
}
