.footer-bar{
    background-color: #00aeef;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 4px;
}
.footer-bar-content{
    width: 50%;
    height: 100%;
}
.footer-bar-content-faq{
    padding-right: 14%;
}

.footer-bar .faq-care {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (min-width: 1001px) {

    .footer-bar .faq-care {
        justify-content: flex-end;
        padding-right: 1em;
    }

}

@media screen and (max-width: 1000px) {

    .footer-bar .faq-care {
        justify-content: space-around;
    }

}

.faq-label, .faq-care{
    width: 50%;
}
.footer-bar .faq-label button {
    font-size: 18px;
}

.footer-bar .faq-care a { color: inherit; } 
.footer-bar .faq-label{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    min-width: 152px;
}
.footer-bar .headset-icon svg{
    font-size: 25px;
    color: #fff;
}
.footer-bar .headset-icon{
    padding-top: 6px;
}

.footer-bar .care-no{
    margin: 0 8px;
    display: flex;
    align-items: center;
}

.footer-bar .care-no a {
    cursor: pointer;
    text-decoration: underline;
}

.settings.btn {
    color: #fff;
    padding: 8px;
    margin-left: 0;
}

.settings.btn:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}

.care-days {
    font-size: 14px;
}
