.status-head {
    font-weight: 600;
}

.status.text {
    line-height: 1.2em;
}

.status-head.success,
.status.icon.success svg {
    color: #50af2d;
}

.status-head:not(.success),
.status.icon:not(.success) svg {
    color: red;
}

.status.icon svg {
    height: 1.5em;
    width: 1.5em;
}

.payment-dialog.actions {
    margin-top: -8px;
    padding-top: 0;
}
