.slot-selection-container {
   margin-top: -8px;
}

/* Tab button */
.slot-selection-tabs .MuiTab-root {
    text-transform: capitalize;
    padding-top: 0;
    padding-bottom: 0;
}

.slot-selection-tabs .MuiTabs-indicator {
    background: #00aeff;
}

/* Button Label */
.slot-selection-tabs .MuiTab-root .MuiTab-wrapper {
    color: #000;
}

.slot-selection-tabs .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: #00aeff;
}
