.mobile-form {
    max-width: 400px;
}

.mobile-form button,
.mobile-form .mobile-input {
    width: 100%;
}

.mobile-form input {
    width: 100%;
}

.mobile-form button {
    margin-top: 2em;
}
.tnc-option{
    margin-top: 0.5em;
}

.tnc-option input{
    width: 40px;
}

.tnc-option span {
    font-size: 14px;
}

.tnc-option .tnc_link{
    cursor: pointer;
    text-decoration: underline;
}
#root{
    height: 100%;
}
.tnc-option .MuiIconButton-label{
    color: #00aeff;
}

.tnc-option .checkbox {
    margin-left: -10px;
}

.not-supported.info {
    margin-bottom: 1em;
    color: grey;
}

.not-supported.head {
    margin-bottom: 1.3em;
    font-size: 20px;
}

.country-dropdown {
    font-size: '14px',
}
