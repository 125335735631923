.faq-nav{
    max-width: 850px;
    margin: 0 auto;
    padding-top: 0.5em;
    color: #00aeff;
    display: flex;
    align-items: flex-start;
}

.faq-label {
    display: inline-block;
}

.faq-label, .faq-care{
    width: 50%;
}

.faq-nav .faq-care a { color: inherit; } 
.faq-nav .faq-label{
    font-size: 25px;
}

.faq-nav .care-no{
    display: flex;
    align-items: center;
}

.care-days {
    font-size: 14px;
}

.faq-care {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.faq-container {
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto;
}

.faq-container .faq-primary-summary,
.faq-container .faq-primary-summary .MuiExpansionPanelSummary-expandIcon {
    color: #00aeff;
}
