.confirm.head {
    color: grey;
    margin-bottom: 1em;
    font-weight: 500;
}

.patient-details {
    font-size: 14px;
    color: grey;
}

.patient-info {
    margin-bottom: 0.5em;
}

.patient.name {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.patient.name button {
    margin-left: 1em;
}

.patient-details > div {
    margin-bottom: 1em;
}

.confirm-gender {
    margin-right: 1em;
}

.confirm-age {
    margin-right: 0.5em;
}

.appointment-info {
    font-size: 14px;
    /* font-weight: 500; */
}

.confirm-apt-date {
    margin-bottom: 8px;
}

.confirm-apt-time {
    color: grey;
    display: flex;
    align-items: center;
    font-weight: 400;
}

.appointment-info,
.amt-info {
    margin-bottom: 1em;
}

.appointment-info .change-apt-link {
    margin-left: 8px;
}

.apt-time.icon {
    font-size: 16px;
    margin-right: 4px;
}

.amt-info {
    font-size: 14px;
    font-weight: 500;
}

.confirm-fee {
    margin-left: 1.5em;
}

.confirm-pay-secure {
    font-size: 12px;
    color: grey;
    display: flex;
    align-items: center;
}

.confirm-pay-secure .secure.icon {
    font-size: 16px;
    color:  #abacab;
    margin-right: 4px;
}

.confirm.actions {
    border-top: 2px solid #ccc;
}

.book-apt.btn {
    color: #4ba528;
}

.book-apt-cancel.btn {
    color: #f93422;
}
