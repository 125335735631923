#userMobileNo {
    width: 120px;
    font-size: 14px;
    color: #000;
}

#userOtpInput {
    font-size: 32px;
    letter-spacing: 1.5em;
}

.verification.head {
    margin-bottom: 1em;
}

.otp-form {
    max-width: 400px;
}

.otp-form button,
.otp-form input {
    width: 100%;
}

.otp.otp-field {
    margin-top: 0.5em;
    width: 100%;
    font-size: 32px;
}

.resend_otp_cnt{
    margin-top: 8px;
    font-size: 11px;
    color: #9d9696;
    margin-bottom: 2em;
}

.resend_otp{
    margin-left: 4px;
    color: #40adf7;
    font-weight: bold;
    cursor: pointer;
}

.user.phone svg {
    font-size: 14px;
    color: black
}

.otp-form button {
    margin-top: 0.5em;
}
