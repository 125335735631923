.appointment-card {
    position: relative;
    margin: 2em 0;
}

.card-info {
    display: flex;
    align-items: center;
}

.checkin-chip-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: -1.5em;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.checkin-chip-container.cancelled {
    margin-right: 0;
    margin-top: 0;
}

.checkin-chip.doxper-btn {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background: #00aeff;
    margin-bottom: 8px;
    min-width: 80px;
}

.checkin-chip.disabled {
    pointer-events: none;
    background: #ccc;
    color: grey;
}

.slot-cancelled {
    color: #f00d1e;
    padding-right: 1em;
}

.appointment-card .appointment-menu {
    position: absolute;
    right: 0;
    top: 0;
}

.seperator {
    border-top: 1px solid #ddd;
    color: #00aeff;
    padding-top: 1em;
}


.apt-pres-download .icon {
    vertical-align: middle;
    height: 20px;
    color: #00aeff;
}

.apt-pres-download .link {
    vertical-align: middle;
}

.slot-time-caption.caption {
    margin-top: 1.5em;
    display: none;
}

.slot-time-caption.caption.show {
    display: block;
}

.slot-time-caption.disabled,
.apt-pres-download.hidden,
.hidden {
    display: none;
}

.user.avatar {
    height: 80px;
    width: 80px;
    background-color: transparent;
    display: inline-block;
    vertical-align: top;
}

.doc-name {
    display: flex;
    align-items: center;
    margin-left: -0.5em;
}

.user.icon {
    height: 50px;
    width: 50px;
    background-color: initial;
    color: #ccc;
    align-self: end;
    margin: 0 8px 0 4px;
}

.user.icon svg {
    height: 125%;
    width: 125%;
}

.user.payment {
    color: grey;
    font-size: 13px;
    font-weight: 500;
    line-height: 1em;
}

.checkin.time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: grey;
    width: 100%;
    flex-basis: 100%;
    margin-left: -2.7em;
    line-height: 1em;
    margin-bottom: 4px;
}

.checkin.time.pending-pay {
    margin-left: 0;
}

.checkin.time svg {
    height: 16px;
    width: 16px;
    color: grey;
    margin-right: 4px;
}

.user.name {
    line-height: 1em;
    margin-bottom: 4px;
}

.doc-name .speciality {
    color: grey;
}

.doc-name .speciality,
.doc-name .location {
    font-size: 12px;
    padding: 0;
}

@media screen and (max-width: 600px) {
    .doc-name .speciality,
    .doc-name .location,
    .user.name {
        width: 130px;
        user-select: none;
    }
}

.card-action-cnt button {
    font-size: 14px;
}

.card-action.btn svg {
    font-size: 16px;
}

.card-action.btn.reports {
    color: #00aeff;
    text-transform: capitalize;
}

.card-action.btn.pres {
    color: #3a855a;
    text-transform: capitalize;
}

.card-action.btn .MuiButton-endIcon {
    margin-left: 0;
}

.info.chip {
    padding: 8px;
}

.info.chip svg {
    height: 18px;
    width: 18px;
}
