.no-match {
    height: 300px;
    text-align: center;
    /* font-size: 20px; */
    color: rgb(0, 174, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.no-match span {
    font-size: 20px;
}
