.drawer-container {
    min-width: 200px;
}

.MuiPaper-root.MuiDrawer-paper {
    background: linear-gradient(150deg, #00aeff 40%, #fff)
}

.side-menu.user-info,
.side-menu.list {
    color: #fff;
}

.side-menu.list {
    padding-left: 8px;
}

.side-menu.list-item {
    text-transform: capitalize;
}

.side-menu.list-item.active {
    border-left: 4px solid #fff;
}

.side-menu.user-info {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0 1em 0;
}

.patient.avatar {
    height: 5.5em;
    width: 5.5em;
    background: #fff;
    margin-bottom: 1em;
}

.patient.avatar span {
    font-size: 3em;
    color: #00aeff;
}
