.devicetesting-container {
    box-shadow: 0 0 8px 0 #000;
    max-width: 900px;
    margin: 0 auto 2em;
    padding: 24px;
    background: #f3f3f3;
    min-height: calc(100vh - 72px);
}

.MuiStepLabel-root.MuiStepLabel-vertical:not(.Mui-disabled) svg:not(.Mui-error),
.doxper.blue {
    color: #00aeff;
}

.ios-steps-container {
    /* display: flex; */
    margin-top: 1em;
    overflow-x: auto;
}

@media screen and (max-width: 800px) {
    .ios-steps-container {
        width: 90vw;
        margin-left: -4.1em;
        /* flex-direction: column; */
        background: white;
        white-space: nowrap;
    }

    .ios-steps-container img {
        height: 400px;
    }
}

@media screen and (min-width: 801px) {
    .ios-steps-container img {
        height: 450px;
    }
}

.ios-steps-container img {
    display: inline-block;
    object-fit: contain;
    margin-bottom: 1em;
    margin-right: 0.5em;
    border: 1px solid #ccc;
}
