.video-container {
    height: 100%;
    width: 100vw;
    position: relative;
    background: linear-gradient(45deg, #00aeff, transparent);;
}

.focus-area {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.focused-video {
    width: 100%;
    height: 100%;
}

.video-title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 0;
    color: #fff;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}

.video-container .consultation-room-share-alert {
    width: 400px;
    margin: 0 auto;
    position: relative;
    top: 25%;
    z-index: 1;
}

.own-video {
    position: absolute;
    bottom: 80px;
    left: 8px;
    height: 150px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 0 4px 0 #00aeff;
    z-index: 2;
}

.own-video video {
    height: 100%;
    width: 100%;
}

.own-video .own-video-elt {
    object-fit: cover;
    transform: scale(-1, 1);
    width: 110px;
}

.own-video .screen-share-container {
    position: relative;
}

.own-video .screen-share-elt {
    width: 200px;
}

.share-screen.alert.action {
    border-color: #ccc;
    color: #fff;
}

.video-controls-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 0 2em 0;
    background: linear-gradient(0deg, rgba(0,0,0, 0.3), transparent 60%);
}

.video-controls {
    display: flex;
    min-width: 240px;
    max-width: 320px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
}

.video-controls > button {
    color: #fff;
    z-index: 3;
}

.video-controls > button.active {
    background: rgba(255, 255, 255, 0.5);
}

.call-end-container {
    width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 16px;
}

.call-end-container .call-end:active,
.call-end-container .call-end:hover,
.call-end-container .call-end:focus,
.call-end-container .call-end {
    background: #a00;
    color: #fff;
    z-index: 3;
}

.wait-bar {
    top: 25%;
    position: relative;
}

.wait-bar .progress {
    color: #00aeff;
}

.wait-bar .wait-text {
    margin-top: 1em;
    padding: 1em;
}
