.slot-tab-panel {
    margin: 1em 0;
    max-height: calc(100vh - 28em);
    overflow-y: auto;
}

.slot-tab-panel .book.slot.tab {
    background-color: #00aeff;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
}

.slot-tab.no-slots {
    margin: 2em 1em 1em;
}

.slot-tab-panel .slot-title {
    font-size: 12px;
    color: grey;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.slot-tab-panel .slot-title svg {
    font-size: 18px;
    color: #abacab;
    margin-right: 0.5em;
}

.slot-tab-panel .slot-title .evening-icon {
    transform: rotate(135deg);
}

.slot-tab-panel .slot-timings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.slot-tab-panel .slot-timings button {
    background: #fff;
    margin: 0 1em 1em;
}

@media screen and (max-width: 380px) {
    .slot-tab-panel .slot-timings.mobile button {
        margin: 0 0 1em;
    }
}

.slot-tab-panel .slot-timings .slot-timing.selected {
    background: #00aeff;
    color: #fff;
    box-shadow: none;
}
