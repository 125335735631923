.work-flow-icons{
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    justify-content: space-evenly;
}
.work-flow-icons img{
    height: 48px;
}

.work-flow-icons > div:nth-of-type(even) {
    display: flex;
    padding-top: 1.2em;
}

.work-flow-icons > div:nth-of-type(odd) {
    text-align: center;
    display: inline-block;
}

.work-flow-icons > div:first-child {
    margin-right: -0.5em;
}

.work-flow-icons > div:last-child {
    margin-left: -0.5em;
}

.work-flow-icons .arrow {
    object-fit: cover;
    height: 8px;
    width: 32px;
    margin: 0 0.5em;
}

.work-flow-icons .text {
    font-size: 14px;
}

.work-flow-desc {
    max-width: 400px;
    padding: 0 24px;
    margin-bottom: 2em;
    margin-top: 4em;
    margin-left: -24px;
}

.checkin {
    margin: 0 0.8em;
}
