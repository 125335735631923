.upload-report-page{
    width: 100%;
    height: 100%;
    position: relative;
}
.upload-report-header, .upload-report-done, .upload-report-cnt{
    position: relative;
}
.upload-report-header{
    display: flex;
}
.upload-report-done{
    height: 40px;
}
.upload-report-done button{
    color: #fff;
    font-size: 19px;
}
.upload-report-cnt{
    height: calc(100% - 120px);
    background: whitesmoke;
}
@media only screen and (min-width: 600px) {
    .image-preview-box{
        max-width: 600px;
        margin: 0 auto;
    }
}
.upload-report-add-cnt{
    width: 100px;
    position: absolute;
    bottom: 13px;
    right: 4px;
}
.upload-report-addmore-btn-cnt{
    width: 56px;
    height: 56px;
    /* border-radius: 50%; */
    position: relative;
    left: calc(50% - 28px);
}
.upload-report-addmore-btn-cnt button, .upload-option-close-modal button{
    width: 100%;
    height: 100%;
    background: #b1e6ff;
    border-radius: 50%;
}
.upload-report-addmore-btn-cnt button svg, .upload-option-close-modal button svg{
    font-size: 40px;
    color: #00AEFF;
}
.upload-report-addmore-label{
    text-align: center;
    margin-top: 7px;
    color: #00AEFF;
    font-size: 12px;
}
.upload-option-cnt{
    background: whitesmoke;
    width: 100%;
    height: 100%;
}
.upload-option-close-modal{
    width: 50px;
    position: absolute;
    bottom: 13px;
    left: calc(50% - 25px);
    height: 50px;
}
.upload-report-option{
    width: 70%;
    position: absolute;
    left: 15%;
    top: 200px;
}
.upload-report-action{
    width: 50%;
    float: left;
    text-align: center;
}
.upload-report-action button{
    width: 84px;
    position: relative;
    /* left: calc(50% - 42px); */
}
.upload-report-action svg{
    font-size: 60px;
}
@media only screen and (min-width: 600px) {
    .upload-report-option{
        max-width: 420px;
        left: calc(50% - 210px);
    }
}
.upload-report-action-label{
    font-size: 17px;
    font-weight: bold;
    color: #a0a0a0;
}
.fileUploader  .fileContainer{
    background: #f5f5f5;
    box-shadow: 0px 0px;
    margin: 0px;
    padding: 20px 0px 0px;
    height: 100%;
    overflow: scroll;
    justify-content: flex-start;
}
.upload-file-option{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 2s ease 0s;
}
.fileUploader{
    height: 100%;
}
.upload-file-action-btn{
    width: 300px;
    height: 80px;
    position: relative;
    left: calc(50% - 150px);
}
.upload-file-option.photo-selected{
    position: absolute;
    top: calc(50% - 163px);
}
.imageUploader{
    position: relative;
    width: 50%;
    float: left;
    text-align: center;
    height: 100%;
}
.imageUploader .upload-btn {
    /* background-color: #00AEFF; */
    color: #00aeff;
    height: 100%;
    width: 100%;
    border-radius: 0;
}
.imageUploader input[type=file] {
    font-size: 100px;
    position: absolute;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    left: calc(50% - 75px);
    cursor: pointer;
}
.imageUploader svg{
    font-size: 50px;
}
.image-preview-box{
    /* background: antiquewhite; */
    position: relative;
    max-height: calc(100% - 101px);
    overflow: scroll;
    top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.image-preview-box .preview-img-cnt{
    width: calc(33% - 40px);
    min-width: 120px;
    margin: 20px;
    float: left;
    box-shadow: 0px 0px 16px 2px #c7c7c7;
    position: relative;
}
.preview-img-cnt img{
    width: 100%;
    border: 1px solid #eee;
}
.preview-img-cnt .preview-img-btn{
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    right: -23px;
    top: -19px;
    cursor: pointer;
}
.preview-img-cnt .preview-img-btn button{
    border-radius: 50%;
    height: 40px;
    outline: none;
}
.uploaded-file-cnt{
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
}
.uploaded-file-cnt .image-preview-box{
    top: 40px;
    max-height: calc(100% - 60px);
}
@media only screen and (min-width: 600px) {
    .uploaded-file-cnt{
        max-width: 600px;
        margin-left: calc(50% - 300px);
    }
}
.blank-impage-preview-box{
    text-align: center;
    color: #00aeff;
    font-size: 24px;
    text-transform: uppercase;
}
.impage-preview-box-count{
    color: #00aeff;
    padding-left: 19px;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
}
.back-navigation-arrow{
    width: 60px;
    height: 60px;
    position: relative;
    top: 10px;
    left: 10px;
    float: left;
}
.back-navigation-arrow button{
    height: 60px;
}
.back-navigation-arrow button svg{
    color: #00aeff;
    font-size: 44px;
}
.header-doc-name{
    font-size: 23px;
    color: #00aeff;
    line-height: 82px;
    width: 335px;
    margin-left: 1em;
}
.upload-report-done{
    text-align: center;
    font-size: 25px;
    line-height: 40px;
    color: #fff;
    background: #00aeff;
}
.action-icon-label{
    color: #00aeff;
    font-weight: bold;
    position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 16px;
}

.reports-loading-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: -1em;
}

/* For MUI Skeleton */
.reports-loading-container > span {
    margin-top: 1em;
    margin-right: 1.5em;
    width: calc(33% - 40px);
    min-width: 120px;
    height: 240px;
}
/* End */

.upload-report.image-viewer {
    max-height: 90vmax;
    object-fit: contain;
    background: rgba(0,0,0,0.5);
    width: 100%;
}

.preview-img-date {
    color: #00aeff;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 4px;
}
