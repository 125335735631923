.participant-video {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.participant-video.large {
    position: absolute;
    top: 0
}

.participant-video.small {
    width: 110px;
    height: 150px;
    border-left: 1px solid #00aeff;
}

.participant-video video {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.participant-video.blur video {
    filter: blur(1em);
    background: #00aeff;
}

.audio-indicator {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.audio-indicator.small {
    left: 50%;
    top: 60%;
    transform: translate(-50%, -60%);
}

.audio-indicator .audio-avatar{
    height: 60px;
    width: 60px;
    background: linear-gradient(180deg, rgba(0,0,0,0.3),  rgba(0,0,0,0.3));
}

.audio-indicator.small .audio-avatar{
    height: 30px;
    width: 30px;
}

.video-indicator {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background: linear-gradient(0deg, rgba(0,0,0,0.2),  rgba(0,0,0,0.2));
    padding: 0 1em 4px 1em;
    border-radius: 1em;
}

.video-indicator.small {
    top: 25%;
    transform: translate(-50%, -25%);
    padding: 0;
}

.video-indicator .video-avatar{
    height: 100px;
    width: 100px;
    background: transparent;
}

.video-indicator.small .video-avatar{
    height: 40px;
    width: 50px;
    background: transparent;
}

.participant-video.blur .video-indicator,
.participant-video.blur .audio-indicator {
    z-index: 1;
}

.audio-indicator .MuiSvgIcon-root,
.video-indicator .MuiSvgIcon-root{
    color: #fff;
}

.audio-indicator.small .MuiSvgIcon-root {
    font-size: 20px;
}

.video-indicator .MuiSvgIcon-root {
    height: 100px;
    width: 100px;
}

.video-indicator.small .MuiSvgIcon-root {
    height: 40px;
    width: 40px;
}
