* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  position: relative;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

.doxper-blue-btn:active,
.doxper-blue-btn:hover,
.doxper-blue-btn:focus {
  background:#00aeff !important;
}

.doxper-blue-btn {
  background: #00aeff !important;
   color: #fff !important
}

.main-page-wrapper {
  min-height: calc(100vh - 72px);
  margin-bottom: 2em;
}

.land-padding {
  padding: 72px 24px 0 24px;
}

.err-land-padding {
  padding: 72px 32px 0 32px;
}

.http-redirector {
  color: #00aeff;
}

.text.center {
  text-align: center;
}

.text.left {
  text-align: left;
}

.text.right {
  text-align: right;
}

.justify.center {
  justify-content: center;
}

.vertical.middle {
  vertical-align: middle;
}

.display.none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.dox.btn.light {
  color: #00aeff;
  border: 1px solid #00aeff;
  background: #fff;
}

.card-action-cnt {
  border-top: 2px solid #f3f3f3;
  justify-content: space-around;
  position: relative;
}

.card-action-cnt .action.divider {
  margin-top: -8px;
  margin-bottom: -8px;
  width: 2px;
}

.card-action-cnt .card-action.btn {
  width: 50%;
}

.list.header {
  color: grey;
}
