.reconnectContainer {
    margin-top: 1em;
}

.call.duration {
    margin-bottom: 1em;
}

.reconnectContainer .reconnectBtn {
    margin-left: 1em;
    color: #fff;
    background: #00aeff;
}

.ratingContainer {
    /* TODO:  Change it back to 2 once reconnect is up for all*/
    margin-top: 1em;
}

.ratingContainer > span {
    font-size: 13px;
}

.ratingInfo {
    display: flex;
    justify-content: space-between;
    width: 10em;
    margin: 0 auto;
    padding-bottom: 1em;
}

.ratingInfo > span {
    color: grey;
    font-size: 10px;
}

.ratingComplete.header {
    margin-bottom: 8px;
    color: #00aeff;
    font-weight: 500;
}
